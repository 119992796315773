import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import moment from "moment"
import Layout from "../components/layout"
import SEO from "../components/seo"
import removeDuplicateTags from "../utils/removeDuplicateTags"
import TagSearch from "../components/tagSearch"
import {
  GridContainer,
  Card,
  CardTags,
  CardTag,
  Tag,
  CardLink,
} from "../components/styled/grid"
import {
  LoadMoreButton,
  LoadMoreContainer,
} from "../components/styled/loadMore"
import { SearchInput, SearchWrapper } from "../components/styled/search"
import { NoData } from "../components/styled/noData"
import { Container } from "../components/styled/container"
import { AdBanner, AdLayout } from "../components/adBanner/index"

const NewsPage = ({ data }) => {
  const [query, searchQuery] = useState("")
  const [selectedTags, selectTag] = useState([])
  const [filteredNews, setFilteredNews] = useState([]) // Filtered Games
  const [limit, setLimit] = useState(10)
  const [allData, setAllData] = useState([])
  const [newTags, setNewTags] = useState([])

  useEffect(() => {
    data.allStrapiPost.edges.map(({ node }) => allData.push(node))
    data.allStrapiPostNew.edges.map(({ node }) => allData.push(node))
    allData.map(node => {
      node.tags.forEach(tag => {
        newTags.push({
          value: tag,
          label: tag,
        })
      })
    })

    setNewTags(removeDuplicateTags(newTags))
  }, [])

  useEffect(() => {
    if (selectedTags !== null && selectedTags.length > 0) {
      let checker = (arr, target) => target.every(v => arr.includes(v))
      let currentTags = selectedTags.map(({ value }) => value)
      const matchOnTag = allData.filter(node => {
        return checker(node.tags, currentTags)
      })

      setFilteredNews(
        matchOnTag.filter(node => {
          return node.title.toLowerCase().includes(query.toLowerCase())
        })
      )
    } else {
      setFilteredNews(
        allData.filter(node => {
          return node.title.toLowerCase().includes(query.toLowerCase())
        })
      )
    }
  }, [query, allData])

  useEffect(() => {
    if (selectedTags !== null && selectedTags.length > 0) {
      searchQuery("")
      let checker = (arr, target) => target.every(v => arr.includes(v))
      let currentTags = selectedTags.map(({ value }) => value)

      setFilteredNews(
        allData.filter(node => {
          return checker(node.tags, currentTags)
        })
      )
    } else {
      searchQuery("")
      setFilteredNews(allData)
    }
  }, [selectedTags])

  const loadMoreNews = () => {
    setLimit(limit + 10)
  }

  const sortFunc = (a, b) => {
    if (a.createdAt < b.createdAt) {
      return 1
    } else if (a.createdAt > b.createdAt) {
      return -1
    } else {
      return 0
    }
  }

  return (
    <Layout>
      <AdLayout>
        <AdBanner slot="7846765506" />
        <Container main style={{ flex: "10 0 auto" }}>
          <SEO title="News - FGCharts" description={data.strapiSeo.newspage} />
          <SearchWrapper>
            <TagSearch selectTag={selectTag} tags={newTags} />
            <SearchInput
              placeholder="Search News..."
              onChange={e => searchQuery(e.target.value)}
              value={query}
            />
          </SearchWrapper>
          <GridContainer>
            {filteredNews.length > 0 ? (
              filteredNews
                .slice(0, limit)
                .sort(sortFunc)
                .map((node, index) => (
                  <CardLink to={`/news/${node.slug}`} key={index}>
                    <Card>
                      <div>
                        <Img
                          fluid={
                            node.game
                              ? node.game.banner.childImageSharp.fluid
                              : node.featuredImage.childImageSharp.fluid
                          }
                          style={{ height: 160 }}
                        />
                      </div>
                      <div style={{ padding: "7px 16px 16px 16px" }}>
                        <h3
                          style={{
                            marginBottom: 8,
                          }}
                        >
                          {node.title}
                        </h3>
                        <p style={{ marginBottom: 8, fontSize: 14 }}>
                          {node.description}
                        </p>
                        <div>
                          <CardTags>
                            {node.tags.slice(0, 3).map((tag, index) => {
                              return (
                                <CardTag red key={index} right>
                                  <Tag>{tag}</Tag>
                                </CardTag>
                              )
                            })}
                            <CardTag>
                              {node.tags.length - 3 > 0 ? (
                                <Tag>{node.tags.length - 3}+</Tag>
                              ) : undefined}
                            </CardTag>
                          </CardTags>
                          <div
                            style={{
                              marginTop: 16,
                            }}
                          >
                            <small>{`${node.author} - ${moment(
                              node.createdAt
                            ).format("LL")}`}</small>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </CardLink>
                ))
            ) : (
              <NoData style={{ gridColumn: "span 3" }}>
                There are currently no news that fit the criteria.
              </NoData>
            )}
          </GridContainer>

          {filteredNews.length > 10 && limit < filteredNews.length ? (
            <LoadMoreContainer>
              <LoadMoreButton onClick={loadMoreNews}>Show More</LoadMoreButton>
            </LoadMoreContainer>
          ) : undefined}
        </Container>
        <AdBanner slot="6517054732" />
      </AdLayout>
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiPostNew {
      edges {
        node {
          id
          createdAt
          slug
          strapiId
          author
          description
          content
          title
          tags
          game {
            banner {
              childImageSharp {
                fluid(maxWidth: 360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allStrapiPost {
      edges {
        node {
          id
          slug
          strapiId
          author
          description
          content
          title
          tags
          createdAt
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 360) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    strapiSeo {
      newspage
    }
  }
`

export default NewsPage
